import { environment } from './app.config';

const base = "v2/"
const baseDropdown = "v2/dropdown/"

export const API = {
    login: environment.apiMain + 'login',
    test: environment.apiMain + 'test',
    contact: environment.apiMain + base + "home/contactForms",
    getContacts: environment.apiMain + base + "home/contacts",
    getContactTypes: environment.apiMain + base + "dropdown/contactFormTypes",
    search: environment.apiMain + base + 'home/servicesByDetailedSearch',
    servicesBySearch: environment.apiMain + base + 'home/servicesBySearch',
    servicesByTag: environment.apiMain + base + 'home/servicesByTag',
    servicesByDetailedSearch: environment.apiMain + base + 'home/servicesByDetailedSearch',
    subscribers: environment.apiMain + base + "home/subscribers",
    subscribersDrop: environment.apiMain + base + "dropdown/subscribers",
    category: environment.apiMain + base + 'home/categories',
    organisation: environment.apiMain + base + 'home/organisations',
    faqs: environment.apiMain + base + 'home/faqs',
    tags: environment.apiMain + base + 'home/tags',
    about: environment.apiMain + base + 'home/about',
    detail: environment.apiMain + base + 'home/service',
    newServices: environment.apiMain + base + 'home/newServices',
    popularServices: environment.apiMain + base + 'home/popularServices',
    contents: environment.apiMain + base + 'home/inputs',
    contentList: environment.apiMain + base + 'home/contents',
    reports: environment.apiMain + base + 'totalRequestCount',
    reportPeriod: environment.apiMain + base + 'requestCountForPeriods',
    reportPercentage: environment.apiMain + base + 'requestPercentageForCategories',
    dropdown: {
        categories: environment.apiMain + baseDropdown + "categories",
        organisations: environment.apiMain + baseDropdown + "organisations",
        fileTypes: environment.apiMain + baseDropdown + "fileTypes",
        languages: environment.apiMain + baseDropdown + "languages",
        contentTypes: environment.apiMain + baseDropdown + "contentTypes",
    }
}
