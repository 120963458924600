import { API } from "core/configs/api.config";
import { GetLang } from "core/helpers/common-functions/common-functions";
import { GET } from "packages/VHttp/GET";
import { IPageParams } from "store/types/store.types";
import { container, injectable } from "tsyringe";
import { CategoryModel } from "../models/category.model";
import { OrganisationModel } from "../models/organisation.model";
import { ServiceModel } from "../models/service.model";
import news_icon from "assets/images/new-info-home.svg"
import popular_icon from "assets/images/popular-home.svg";


@injectable()
export class CategoriesServices {
    private _get: GET = container.resolve(GET);

    getCategories(params: IPageParams): Promise<any> {
        return this._get.setApi(API.category)
            .setParams(params)
            // .setHeaders({
            //     "Accept-Language": GetLang()
            // })
            .requestPromise()
            .then((res) => {
                return new CategoryModel(res.data.categories)
            })
    }

    getOrganisations(params: IPageParams): Promise<any> {
        return this._get.setApi(API.organisation)
            .setParams(params)
            // .setHeaders({
            //     "Accept-Language": GetLang()
            // })
            .requestPromise()
            .then((res) => {
                return new OrganisationModel(res.data.organisations)
            })
    }

    getNewServices(params: IPageParams): Promise<any> {
        return this._get.setApi(API.newServices)
            .setParams({ ...params, serviceType: 1 })
            // .setHeaders({
            //     "Accept-Language": GetLang()
            // })
            .requestPromise()
            .then((res) => {
                return new ServiceModel({ data: res.data, icon: news_icon })
            })
    }

    getPopularServices(params: IPageParams): Promise<any> {
        return this._get.setApi(API.popularServices)
            .setParams({ ...params, serviceType: 2 })
            // .setHeaders({
            //     "Accept-Language": GetLang()
            // })
            .requestPromise()
            .then((res) => {
                return new ServiceModel({ data: res.data, icon: popular_icon })
            })
    }

}