import React from 'react'
import "./not_found.components.scss"
import not_found_faq from "../../assets/images/faq_not_found.svg"
import {useSelector} from 'react-redux';
import {RootState} from '../../store/types/store.types';

const NotFoundComponent = () => {

  const contents = useSelector((state: RootState) => state.publicState.contents)

  return (
    <div className='faq_not_found_section'>
        <div className="faq_not_found">
            <div className="faq_not_found_img">
                <img src={not_found_faq} alt="" />
            </div>
            <div className="faq_not_found_content">
                <h4>{contents?.noResultFound}</h4>
            </div>
        </div>
    </div>
  )
}

export default NotFoundComponent