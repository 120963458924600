import { loading } from "core/layouts/public/store/actions";
import { Dispatch } from "redux";
import { IActionCreator, IPageParams } from "store/types/store.types";
import { container } from "tsyringe";
import { CategoriesServices } from "../services/caterogies.service";
import { DropdownServices } from "../services/dropdown.service";
import { FaqServices } from "../services/faq.service";
import { ICategory, IDropdown, IFaq, IOrganisation } from "../types/types";
import {FaqTypes, HomeTypes, ReportTypes} from './action-types';
import {ReportServices} from '../services/report.service';

const service = container.resolve(CategoriesServices);

export const getCategoriesSuccess = (categories: ICategory[]) => ({
    type: HomeTypes.GET_CATEGORIES_SUCCESS,
    payload: categories
})

export const getCategories = (params: IPageParams) => (
    (dispatch: Dispatch<IActionCreator>) => {
        dispatch(loading(true))
        service.getCategories(params)
            .then(res => {
                dispatch(getCategoriesSuccess(res))
            }).catch(err =>
                console.error(err)
            ).then(() =>
                dispatch(loading(false))
            )
    }
)

export const getOrganisationsSuccess = (optanisation: IOrganisation[]) => ({
    type: HomeTypes.GET_ORGANISATIONS_SUCCESS,
    payload: optanisation
})

export const getOrganisations = (params: IPageParams) => (
    (dispatch: Dispatch<IActionCreator>) => {
        dispatch(loading(true))
        service.getOrganisations(params)
            .then(res => {
                dispatch(getOrganisationsSuccess(res))
            }).catch(err =>
                console.error(err)
            ).then(() =>
                dispatch(loading(false))
            )
    }
)

const serviceFaq = container.resolve(FaqServices);

export const getFaqSuccess = (faqs: IFaq[]) => ({
    type: FaqTypes.GET_FAQ_SUCCESS,
    payload: faqs
})
export const getFaqError = (err: IFaq[]) => ({
    type: FaqTypes.GET_FAQ_SUCCESS,
    payload: err
})

export const getFaqs = (data:any) => (
    (dispatch: Dispatch<IActionCreator>) => {
        serviceFaq.getFaqs(data).then((res: any) => {
            dispatch(loading(true))
            dispatch(getFaqSuccess(res))
        }).catch(err => {
            dispatch(getFaqError(err))
        }
        ).then(() =>
            dispatch(loading(false))
        )
    }
)



export const getNewSuccess = (faqs: IFaq[]) => ({
    type: HomeTypes.GET_NEW_SUCCESS,
    payload: faqs
})

export const getNewServices = (params: IPageParams) => (
    (dispatch: Dispatch<IActionCreator>) => {
        service.getNewServices(params).then((res: any) => {
            dispatch(loading(true))
            dispatch(getNewSuccess(res))
        }).catch(err => {
            console.error(err)
        }
        ).then(() =>
            dispatch(loading(false))
        )
    }
)

export const getPopularSuccess = (faqs: IFaq[]) => ({
    type: HomeTypes.GET_POPULAR_SUCCESS,
    payload: faqs
})

export const getPopularServices = (params: IPageParams) => (
    (dispatch: Dispatch<IActionCreator>) => {
        service.getPopularServices(params).then((res: any) => {
            dispatch(loading(true))
            dispatch(getPopularSuccess(res))
        }).catch(err => {
            console.error(err)
        }
        ).then(() =>
            dispatch(loading(false))
        )
    }
)


//#region  Dropdown
const serviceDropdown = container.resolve(DropdownServices);

export const getDropdownSuccess = ({ name, data }: { name: string, data: IDropdown[] }) => ({
    type: HomeTypes.GET_DROPDOWN_SUCCESS,
    payload: { [name]: data }
})

export const getDropdownCategories = () => (
    (dispatch: Dispatch<IActionCreator>) => {
        dispatch(loading(true))
        serviceDropdown.getCategories().then((res: any) => {
            dispatch(getDropdownSuccess({ name: "dropdown_categories", data: res.list }))
        }).catch(err => {
            dispatch(getFaqError(err))
        }
        ).then(() =>
            dispatch(loading(false))
        )
    }
)

export const getDropdownOrganisations = () => (
    (dispatch: Dispatch<IActionCreator>) => {
        dispatch(loading(true))
        serviceDropdown.getOrganisations().then((res: any) => {
            dispatch(getDropdownSuccess({ name: "dropdown_organisations", data: res.list }))
        }).catch(err => {
            dispatch(getFaqError(err))
        }
        ).then(() =>
            dispatch(loading(false))
        )
    }
)

export const getDropdownFileTypes = () => (
    (dispatch: Dispatch<IActionCreator>) => {
        dispatch(loading(true))
        serviceDropdown.getFileTypes().then((res: any) => {
            dispatch(getDropdownSuccess({ name: "dropdown_fileTypes", data: res.list }))
        }).catch(err => {
            dispatch(getFaqError(err))
        }
        ).then(() =>
            dispatch(loading(false))
        )
    }
)

export const getDropdownContent = () => (
    (dispatch: Dispatch<IActionCreator>) => {
        dispatch(loading(true))
        serviceDropdown.getContent().then((res: any) => {
            dispatch(getDropdownSuccess({ name: "dropdown_content", data: res.list }))
        }).catch(err => {
            dispatch(getFaqError(err))
        }
        ).then(() =>
            dispatch(loading(false))
        )
    }
)

export const getDropdownLanguages = () => (
    (dispatch: Dispatch<IActionCreator>) => {
        dispatch(loading(true))
        serviceDropdown.getLanguages().then((res: any) => {
            dispatch(getDropdownSuccess({ name: "dropdown_languages", data: res.list }))
        }).catch(err => {
            dispatch(getFaqError(err))
        }
        ).then(() =>
            dispatch(loading(false))
        )
    }
)

const reportService = container.resolve(ReportServices);


export const getReportCountService = (): IActionCreator => (
  {
    type: ReportTypes.GET_REPORT_COUNT_START
  }
)

export const getReportCountSuccess = (data: any): IActionCreator => (
  {
    type: ReportTypes.GET_REPORT_COUNT_SUCCESS,
    payload: data
  }
)

export const getReportCountFail = (err: any): IActionCreator => (
  {
    type: ReportTypes.GET_REPORT_COUNT_FAIL,
    payload: err
  }
)

export const getReportCountListService = () => (
  (dispatch: Dispatch<IActionCreator>) => {
    dispatch(getReportCountService())
    return  reportService.getReports().then(res => {
      dispatch(getReportCountSuccess(res))
    }).catch(err => {
      dispatch(getReportCountFail(err))
      return Promise.reject(err)
    })
  }
)


export const getReportPeriodSuccess = (data: any): IActionCreator => (
  {
    type: ReportTypes.GET_REPORT_PERIOD_SUCCESS,
    payload: data
  }
)

export const getReportPeriodService = () => (
  (dispatch: Dispatch<IActionCreator>) => {
    return  reportService.getReportsForPeriod().then(res => {
      dispatch(getReportPeriodSuccess(res))
    }).catch(err => {
      return Promise.reject(err)
    })
  }
)

export const getReportPercentageSuccess = (data: any): IActionCreator => (
  {
    type: ReportTypes.GET_REPORT_PERCENTAGE_SUCCESS,
    payload: data
  }
)

export const getReportPercentageService = () => (
  (dispatch: Dispatch<IActionCreator>) => {
    return  reportService.getReportPercentage().then(res => {
      dispatch(getReportPercentageSuccess(res.data))
    }).catch(err => {
      return Promise.reject(err)
    })
  }
)

//#endregion Dropdown