import React, {  useState } from "react";
import "./accardion.component.scss";
import DividerComponent from "packages/RDivider/divider.components";
import ButtonComponent from "packages/RButton/button.component";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IAccardion } from "./types/accardion.types";
import PaginationComponent from "packages/RPagination/pagination.component";
import { useHistory } from "react-router-dom";
import NotFoundComponent from "packages/RNotFound/not_found.components";
import {useSelector} from 'react-redux';
import {RootState} from '../../core/layouts/public/types/public';
import {GetLang} from '../../core/helpers/common-functions/common-functions';

const AccardionComponent = ({faqs,more_acc,paginationData,fetchFAQ,paginate,question,PageSize}:IAccardion) => {
  const contents = useSelector((state: RootState) => state.publicState.contents)
  const [selected, setSeleced] = useState(null);
  const history = useHistory()
  const goFaq = ()=>{
    history.push(`/${GetLang()}/faq`)
    window.scrollTo(0,0)
  }
  const toggle = (i: any) => {
    if (selected === i) {
      return setSeleced(null);
    }
    setSeleced(i);
  };
  return (
    <div>
      {
        faqs ?  <div className="accardion_section" id="faq">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="accardion_wrapper">
               {
                 question && <h1>{contents.queries}</h1>
               }
                <ul className="accardion_list mb-35">
                  {faqs &&
                    faqs.items?.map((acc: any, i: any) => (
                      <li
                        key={i}
                        onClick={() => toggle(i)}
                        className={`accardion_list_item ${
                          selected === i ? "active" : ""
                        }`}
                      >
                        <div className="accardion_title">
                          <p className={'accordion_title-text'}>{acc.title}</p>
                          <p
                            className={`accardion_icon ${
                              selected === i ? "active" : ""
                            }`}
                          >
                            <FontAwesomeIcon icon={"chevron-down"} />
                          </p>
                        </div>
                        <div className="answer_container">
                          <div
                            className={`accardion_answer ${
                              selected === i ? "content_show" : ""
                            }`}
                          >
                            <DividerComponent className="acc_divider" />
                            <p>{acc.description}</p>
                          </div>
                        </div>
                      </li>
                    ))}
                </ul>
               {
                 paginate &&  <PaginationComponent
                 totalCount={paginationData?.totalCount}
                 totalPages={paginationData?.totalPages}
                 prev={paginationData?.hasPreviousPage}
                 next={paginationData?.hasNextPage}
                 changeCount={fetchFAQ}
                 pageIndex={faqs.pagination?.pageIndex}
               />
               }
  
              </div>
            </div>
           {
             more_acc &&  <div className="col-12 text-center mt-40">
             <ButtonComponent classNames=" primary-btn btn--rounded-circle " click={goFaq}>
               {contents.showMore}
             </ButtonComponent>
           </div>
           }
          </div>
        </div>
      </div> : <NotFoundComponent/>
      }
    </div>
  );
};

export default AccardionComponent;
