import {container, injectable} from 'tsyringe';
import {GET} from '../../../packages/VHttp/GET';
import {API} from '../../../core/configs/api.config';
import {ReportModel} from '../models/report.model';

@injectable()
export class ReportServices {
  private _get: GET = container.resolve(GET);

  getReports(): Promise<any> {
    return this._get.setApi(API.reports )
      .requestPromise()
      .then((res) => {
        return res.data
      })
  }

  getReportsForPeriod(): Promise<any> {
    return this._get.setApi(API.reportPeriod)
      .requestPromise()
      .then((res) => {
        return res.data
      })

  }

  getReportPercentage(): Promise<any> {
    return this._get.setApi(API.reportPercentage)
      .requestPromise()
      
  }

}