import AccardionComponent from "packages/RAccardion/accardion.component";
import InputComponent from "packages/RInput/input.component";
import { getFaqs } from "pages/home/store/actions";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "store/types/store.types";
import "./faq.component.scss";
import search_icon from "../../assets/images/search.svg"
import { toast, ToastContainer } from "react-toastify";
import NotFoundComponent from "packages/RNotFound/not_found.components";

const FaqComponent = () => {
  const contents = useSelector((state: RootState) => state.publicState.contents)
  const faqs = useSelector((state: RootState) => state.home.faqs);
  const [data, setData] = useState({
    searchParams: ""
  })
  const [,setPageNum] = useState(1)
  const paginationData = faqs.pagination;
  const dispatch = useDispatch();


  const { searchParams } = data;

  const faqSearchChange = (e: any) => {
    const { name, value } = e.target;
    setData({ ...data, [name]: value })
  }
  const getFaqSearch = () => {
    dispatch(getFaqs({...data, PageNumber: 1, PageSize: 10}))

    if(data.searchParams === ''){
      dispatch(getFaqs({PageNumber: 1, PageSize: 10}))
    }
    if (faqs.pagination.totalPages < 2) {
    }
  }
  const pressEnter = (e: any) => {
    if (e.key === 'Enter') {
      dispatch(getFaqs({searchParams:data.searchParams, PageNumber: 1, PageSize: 10}))

      if(data.searchParams === ''){
        dispatch(getFaqs({PageNumber: 1, PageSize: 10}))
      }
      if (faqs.pagination.totalPages <= 2) {

      }
    }
  }

  const fetchFAQ = (pageNumber: any) => {
    setPageNum(pageNumber)
    if(data.searchParams?.trim() !== ''){
      dispatch(getFaqs({ searchParams:data.searchParams,PageNumber: pageNumber, PageSize: 10 }));
    }else {
      dispatch(getFaqs({PageNumber: pageNumber, PageSize: 10 }));
    }
  };

  useEffect(() => {
    dispatch(getFaqs({ PageNumber: 1, PageSize: 10 }));
  }, [dispatch]);

  return (
    <section className="faq_page">
      <div className="container">
        <ToastContainer />
        <div className="faq">
          <div className="info-search-input">
            <InputComponent
              name="searchParams"
              value={searchParams}
              type="text"
              placeholder={contents.enterTheWordToSearch}
              className="faq_input"
              onChange={faqSearchChange}
              onKeyDown={pressEnter}
            />
            <img src={search_icon} alt="" onClick={getFaqSearch} />
          </div>
        </div>
        {faqs?.items?.length ?
          <AccardionComponent faqs={faqs} paginate={true} paginationData={paginationData} fetchFAQ={fetchFAQ} />
          :
          <NotFoundComponent />
        }
      </div>
    </section>
  );
};

export default FaqComponent;
