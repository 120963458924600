import { IActionCreator } from "store/types/store.types";
import { IFaq, IHomeReduxState } from "../types/types";
import {FaqTypes, HomeTypes, ReportTypes} from './action-types';

const initialState: IHomeReduxState = {
    categories: null,
    organisations: null,
    popularServices: null,
    newServices: null,
    faqs: [],
    faqError: [],
    dropdown_categories: [],
    dropdown_organisations: [],
    dropdown_fileTypes: [],
    dropdown_content: [],
    dropdown_languages: [],
    reportCount: null,
    reportPeriod: null,
    reportPercentage: null
}

export const HomeReducer = (state = initialState, { type, payload }: IActionCreator) => {
    switch (type) {
        case ReportTypes.GET_REPORT_COUNT_SUCCESS:
            return {
                ...state,
                reportCount: payload
            }
        case ReportTypes.GET_REPORT_PERIOD_SUCCESS:
            return {
                ...state,
                reportPeriod: payload
            }
        case ReportTypes.GET_REPORT_PERCENTAGE_SUCCESS:
            return {
                ...state,
                reportPercentage: payload
            }
        case HomeTypes.GET_CATEGORIES_SUCCESS:
            return {
                ...state,
                categories: payload
            }
        case HomeTypes.GET_ORGANISATIONS_SUCCESS:
            return {
                ...state,
                organisations: payload
            }
        case HomeTypes.GET_DROPDOWN_SUCCESS:
            return {
                ...state,
                ...payload
            }
        case FaqTypes.GET_FAQ_SUCCESS:
            return {
                ...state,
                faqs: payload
            }
        case FaqTypes.GET_FAQ_ERROR:
            return {
                ...state,
                faqError: payload
            }
        case HomeTypes.GET_NEW_SUCCESS:
            return {
                ...state,
                newServices: payload
            }
        case HomeTypes.GET_POPULAR_SUCCESS:
            return {
                ...state,
                popularServices: payload
            }
        default:
            return state;
    }
}


