import React, {useCallback, useEffect, useState} from 'react';
import 'reflect-metadata';
import {BrowserRouter as Router} from 'react-router-dom';
import './App.scss';
import 'react-toastify/dist/ReactToastify.css'
import {LayoutRoutes} from './router/router';
import {DbService} from './assets/db/db.service';
import store from "./store/store.worker";
import {Provider} from "react-redux";
import {wrapStore} from "redux-in-worker";
import { ApiInterceptor, ApiInterceptorResponse } from 'core/interceptors/api.interceptor';
import { container } from 'tsyringe';
import ReactGa from 'react-ga';
import ModalComponent from "./packages/RModal/modal.component";
import ictimai from "./assets/images/ictimai.svg";
import ButtonComponent from "./packages/RButton/button.component";

// const worker = new Worker(new URL('./store/store.worker', import.meta.url));
// const workerStore = wrapStore(worker, store.getState());

// const MEASUREMENT_ID = "G-NRL84YWKXC";
// ReactGa.initialize(MEASUREMENT_ID);

function App() {
    useEffect(() => {
        // tslint:disable-next-line: no-unused-expression
        new DbService();
    }, [])
    const interceptorRes = container.resolve(ApiInterceptorResponse)
    const interceptor = container.resolve(ApiInterceptor)
    return (
        <Provider store={store}>
            <Router>
                <LayoutRoutes/>
            </Router>
        </Provider>
    );
}

export default App;
