export enum HomeTypes {
    GET_CATEGORIES_SUCCESS = "GET-CATEGORIES-SUCCESS",
    GET_ORGANISATIONS_SUCCESS = "GET_ORGANISATIONS_SUCCESS",
    GET_DROPDOWN_SUCCESS = "GET_DROPDOWN_SUCCESS",
    GET_NEW_SUCCESS = "GET_NEW_SUCCESS",
    GET_POPULAR_SUCCESS = "GET_POPULAR_SUCCESS",
}



export enum FaqTypes {
    GET_FAQ_SUCCESS = "GET_FAQ_SUCCESS",
    GET_FAQ_ERROR = "GET_FAQ_ERROR"
}

export enum ReportTypes {
    GET_REPORT_COUNT_START = 'GET_REPORT_COUNT_START',
    GET_REPORT_COUNT_SUCCESS = 'GET_REPORT_COUNT_SUCCESS',
    GET_REPORT_PERIOD_SUCCESS = 'GET_REPORT_PERIOD_SUCCESS',
    GET_REPORT_PERCENTAGE_SUCCESS = 'GET_REPORT_PERCENTAGE_SUCCESS',
    GET_REPORT_COUNT_FAIL = 'GET_REPORT_COUNT_FAIL',
}

