import { IFilter } from "../types/types";
export class ServiceModel {
    public count: number = 0;
    public itemsFilter: IFilter[] = [];

    constructor(item: any) {
        this._setCount(item.data);
        this._setItemsFilter(item);

    }

    /**
     * Set Count
     * @param count
     * @private
     */

    private _setCount({ serviceCount }: any): void {
        this.count = serviceCount;
    }

    /**
    * Set ItemsFilter
    * @param itemsFilter
    * @private
    */

    private _setItemsFilter({ data, icon }: any): void {
        let arr: IFilter[] = []
        data.services.items.map((item: { id: number, title: string }) =>
            arr.push(
                {
                    key: item.id,
                    value: item.title,
                    icon: icon,
                    serviceCount: null,
                })

        )
        this.itemsFilter = [...arr]
    }

}
