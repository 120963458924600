import { SubscribersActionTypes } from "./action-types";

const initialState = {
  items: [],
  contentList: []
};

export const SubscribersReducer = (state = initialState, { type, payload }: any) => {
  switch (type) {
    case SubscribersActionTypes.SUBSCRIBERS_SUCCESS:
      return {
        ...state,
        items: payload
      };
    case SubscribersActionTypes.SUBSCRIBERS_FAIL:
      return {
          ...state,
      };
    case SubscribersActionTypes.CONTENT_SUCCESS:
      return {
        ...state,
        contentList: payload
      }
    case SubscribersActionTypes.CONTENT_FAIL:
      return {
        ...state
      }
    default:
      return state;
  }
};
