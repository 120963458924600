export class PublicModel {
    public items: any = null;

    constructor(item: any) {
        this._setItems(item.MessageKeywords);
    }

    /**
     * Set Id
     * @param id
     * @private
     */

    private _setItems(contents: any): void {
        let helper = {}
        contents.map((content: any) => {
            helper = {
                ...helper,
                [content.name]: content.text
            }
        })
        this.items = helper;
    }

}
