import { Redirect, Route } from 'react-router-dom';
import React, {lazy, useCallback, useEffect, useState} from 'react';
import CustomSwitchComponent from './custom-switch/custom-switch.component';
import { useSelector } from "react-redux";
import { checkClaims } from "../core/helpers/common-functions/common-functions";
import FaqComponent from 'pages/Faq/faq.component';
import ModalComponent from "../packages/RModal/modal.component";
import ictimai from "../assets/images/ictimai.svg";
import ButtonComponent from "../packages/RButton/button.component";
import bg from "../assets/images/bg_modal.png";
const PublicLayout = lazy(() => import('../core/layouts/public/public-layout.component'))
const AuthComponent = lazy(() => import('../core/layouts/auth/auth.component'))
const HomeComponent = lazy(() => import('../pages/home/home.component'))
const ContactComponent = lazy(() => import('pages/contact/contact.component'))
const InformationDetailComponent = lazy(() => import('pages/information-detail/information-detail.component'))
const AboutComponent = lazy(() => import('pages/about/about.component'))
const LoginComponent = lazy(() => import('../pages/login/login.component'))
const RegisterComponent = lazy(() => import("../pages/register/register.component"))
const ErrorComponent = lazy(() => import('../core/layouts/error/error.component'))
const NotFound = lazy(() => import('../pages/not-found/notfound.component'))
const InformationComponent = lazy(() => import("pages/information/information.component"));


const layoutRouteList = [
  {
    component: PublicLayout,
    exact: false,
    path: "",
  },
  {
    component: AuthComponent,
    exact: false,
    path: "/auth",
  },
  {
    component: ErrorComponent,
    exact: false,
    path: "/error",
  },
];

const publicRoutes = [
  {
    component: HomeComponent,
    exact: true,
    path: '/:lang',
    name: 'Home',
    claims: []
  },
  {
    component: ContactComponent,
    exact: true,
    path: '/:lang/contact',
    name: 'Contact',
  },
  {
    component: InformationDetailComponent,
    exact: true,
    path: '/:lang/information/:currentPage/:id',
    name: 'InfoDetail'
  },
  {
    component: AboutComponent,
    exact: true,
    path: '/:lang/about',
    name: 'About',
    claims: []
  },
  {
    component: FaqComponent,
    exact: true,
    path: '/:lang/faq',
    name: 'Faq',
    claims: []
  },
  {
    component: InformationComponent,
    exact: true,
    path: "/:lang/information/:currenPage",
    name: "Information",
    claims: [],
  },

];

const authRoutesList = [
  {
    component: LoginComponent,
    exact: true,
    path: "/auth/login",
  },
  {
    component: RegisterComponent,
    exact: true,
    path: "/auth/register",
  },
  {
    component: RegisterComponent,
    exact: true,
    path: "/auth/register",
  },
];

const errorRouteList = [
  {
    component: NotFound,
    exact: true,
    path: "/error/not-found",
  },
];

export const LayoutRoutes = () => {
  const [open,setOpen] =useState<any>(localStorage.getItem("opd_modal") ? false:true)

  const modalEveryWhere = () =>{
    // localStorage.setItem("opd_modal","modal")
      setOpen(false)
  }
  return (
    <>
      <ModalComponent size={"200"} show={false} color="modal_everywhere_color"  custom_class="custom_modal_div" position="modal_wrapper" custom_overlay="modal_custom_overlay">
        <div className="modal_everywhere_wrapper">
          <div className="modal_everywhere_content">
            <div className="modal_everywhere_img">
              <img src={ictimai} alt=""/>
            </div>
            <div className="modal_everywhere_content_wrapper">
              <div className="modal_everywhere_content_title">
                <h1>“Rəqəmsal İnkişaf və Nəqliyyat Nazirliyi tərəfindən Açıq məlumatlar portalının yeni versiyası istifadəyə verilmişdir.</h1>
              </div>
              <div className="modal_everywhere_content_desc">
                <p>Yeni portalda son texnologiyaların tətbiqi ilə dayanıqlı arxitekturanın qurulması,
                  məlumatların insan tərəfindən oxunabilən formatda təqdim edilməsi, inteqrasiya prosesinin
                  sadələşdirilməsi kimi bir çox yeni funksiyalar tətbiq edilmiş, 400-dən yuxarı xidmətin
                  inteqrasiya təmin edilmişdir.
                </p>
              </div>
              <div className="modal_everywhere_content_desc">
                <p>Hal-hazırda həm yeni, həmdə əvvəlki sistemdə fəaliyyət göstərən xidmətlər (servislər) işlək
                  vəziyyətdədir. <span className="modal_everywhere_content_warning">15.12.2022 tarixindən etibarən isə əvvəlki xidmətlərin fəaliyyyəti
                            dayandırılacaq.</span> Qeyd edilən tarixə qədər istifadə etdiyiniz servislərin yeni versiyaya
                  keçidi təmin edilməlidir. Xahiş edirik nəzərə alasınız. “
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="modal_everywhere_btn_wrapper">
          <div className="modal_everywhere_btn">
            <div className="d-flex align-center">
              <ButtonComponent click={()=>modalEveryWhere()}>Bagla</ButtonComponent>
            </div>
          </div>
        </div>
      </ModalComponent>

      <CustomSwitchComponent key="LayoutRoutes">
        <Route exact path={"/"}>
          <Redirect to={"az"} />
        </Route>
        {layoutRouteList.map((route) => (
          <Route
            {...route}
            component={() => <route.component />}
            key={route.path}
          ></Route>
        ))}
      </CustomSwitchComponent>
    </>
  );
};

export function PublicRoutes(props: any) {
  const userClaims = useSelector(({ auth }: any) => auth.user).Claims;
  useEffect(() => { }, [userClaims]);


  return (
    <>
      <CustomSwitchComponent key="PublicRoutes" from={props.from} to={props.to}>
        {publicRoutes.map((route, index) => {
          const newRoute = (
            <Route
              key={route.name + route.path}
              {...route}
              component={(routeProps: any) => {
                const crumbs = publicRoutes
                  .filter((route: any) => {
                    return routeProps.match.path.includes(route.path);
                  })
                  .map(({ path, name, ...rest }) => {
                    let n;
                    const altName =
                      routeProps.location.state &&
                        routeProps.location.state.data &&
                        routeProps.location.state.data.name
                        ? (n = routeProps.location.state.data.name)
                        : (n = "");
                    name === "*" ? (n = altName) : (n = name);
                    return {
                      path: Object.keys(routeProps.match.params).length
                        ? Object.keys(routeProps.match.params).reduce(
                          (path, param) =>
                            path.replace(
                              `:${param}`,
                              routeProps.match.params[param]
                            ),
                          path
                        )
                        : path,
                      name: n,
                      ...rest,
                    };
                  });
                return (
                  <route.component {...route} {...routeProps} crumbs={crumbs} />
                );
              }}
            />
          );
          let hasClaims = true;
          route.claims?.forEach((cl) => {
            hasClaims = checkClaims(userClaims, cl) && hasClaims;
          });
          // return token ?
          // route.claims && route.claims?.length > 0 ?
          //     // route.claims?.map(cl => {
          //     //     return checkClaims(userClaims, cl) ?
          //     //         newRoute
          //     //         :
          //     //         null
          //     // })
          //     hasClaims ? newRoute : null
          //     :
          return newRoute;
          // : <Redirect to='/auth' />
        })}
      </CustomSwitchComponent>
    </>
  );
}

export const AuthRoutes = (props: any) => {
  return (
    <>
      <CustomSwitchComponent key="AuthRoutes" from={props.from} to={props.to}>
        {authRoutesList.map((route) => (
          // !token ?
          //     <Route {...route}
          //            component={(props: any) => (
          //                <route.component {...props} />
          //            )}
          //            key={route.path}
          //     >
          //     </Route>
          //     :
          <Redirect to="//az" />
        ))}
      </CustomSwitchComponent>
    </>
  );
};

export const ErrorRoutes = (props: any) => {
  return (
    <>
      <CustomSwitchComponent key="ErrorRoutes" from={props.from} to={props.to}>
        {errorRouteList.map((route) => (
          <Route
            {...route}
            component={() => <route.component />}
            key={route.path}
          ></Route>
        ))}
      </CustomSwitchComponent>
    </>
  );
};
